import React, { useState, useEffect, useRef } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { ArrowTopIcon, AttachIcon, CloseIcon } from "./Icons";

export default function Input({ model, chatId, setChatId, imageSettings }) {
    const [inputValue, setInputValue] = useState('');
    const textareaRef = useRef(null);
    const fileInputRef = useRef(null);
    const [attachedImages, setAttachedImages] = useState([]);
    let currentChatId = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            let newHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = `${newHeight}px`;
        }
    }, [inputValue]);

    const sendButtonStyle = {
        backgroundColor: inputValue ? '#FFFFFF' : '#424242',
        cursor: inputValue ? 'pointer' : 'default'
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadImage(file);
        }
    };

    const handleAttachClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const uploadImage = (file) => {
        const formData = new FormData();
        formData.append('token', localStorage.getItem('token'));
        formData.append('image', file);

        fetch('https://chatai.uz/api/v2/image/upload', {   
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            setAttachedImages([...attachedImages, data.image]);
        })
        .catch(error => {
            console.error(error);
        });
    };

    const handleRemoveImage = (index) => {
        setAttachedImages(attachedImages.filter((_, i) => i !== index));
    };

    const handleSendMessage = () => {
        if (inputValue.trim()) {
            setAttachedImages([]);
            if (chatId === null) {
                newChat().then(() => {
                    sendMessage();
                });
            } else {
                sendMessage();
            }
            setInputValue('');
            unfocusFromInput();
        }
    };

    const sendMessage = () => {
        let body = new FormData();
        body.append('token', localStorage.getItem('token'));
        body.append('text', inputValue);
        body.append('model', model);
        body.append('context', 10);

        if (chatId != null){
            body.append('chat_id', chatId);
        } else {
            body.append('chat_id', currentChatId.current);
        }
    
        if (attachedImages.length){
            body.append('images', attachedImages.join(','));
        }

        if (model === 'image') {
            body.append('quality', imageSettings.quality);
            body.append('shape', imageSettings.shape);
        }
    
        fetch('https://chatai.uz/api/v2/message/send', {method: 'POST', body: body})
        .catch(error => {
            console.error(error);
        });
    };
    
    const newChat = () => {
        let body = new FormData();
        body.append('token', localStorage.getItem('token'));
    
        return fetch('https://chatai.uz/api/v2/chat/new', {method: 'POST', body: body})
        .then(response => response.json())
        .then(data => {
            setChatId(data.chat_id);
            currentChatId.current = data.chat_id;
        })
        .catch(error => {
            console.log(error);
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && (window.innerWidth > 800)) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].kind === 'file' && items[i].type.startsWith('image/')) {
                const file = items[i].getAsFile();
                if (file) {
                    uploadImage(file);
                }
            }
        }
    };

    function focusOnInput(){
        const input = document.getElementsByClassName('input')[0];
        input.focus();
        input.click();
    }

    function unfocusFromInput(){
        const input = document.getElementsByClassName('input')[0];
        input.blur();
    }

    useEffect(() => {
        if (window.innerWidth > 800){
            focusOnInput();
        }
    }, [])

    let isMouseDown = false;

    document.addEventListener('mousedown', () => {
        isMouseDown = true;
    });

    document.addEventListener('mouseup', () => {
        isMouseDown = false;
    });

    function handleMouseMove(event) {
        if (!isMouseDown) {
            return;  // If the mouse is not down, don't do anything
        }

        const box = document.getElementsByClassName('messagesBox')[0];
        const rect = box.getBoundingClientRect();
        const mouseY = event.clientY - rect.top;

        if ((mouseY > rect.height - 100) && (window.innerWidth > 800)) {
            box.scrollTop += 1;
        }
    }

    document.addEventListener('mousemove', handleMouseMove);

    return (
        <div className="inputWrapper" onMouseMove={handleMouseMove}>
            <div className="sideBarPlaceholder"></div>

            <div className="inputBoxWrapper">
                <div className="inputBox">
                    <div className="attachedImages">
                        {attachedImages.map((image, index) => (
                            <div key={index} className="imageWrapper">
                                <img src={image} alt={`attachment-${index}`} className="attachedImage" />
                                <div className="removeImageIcon" onClick={() => handleRemoveImage(index)}>
                                    <CloseIcon />
                                </div>
                            </div>
                        ))}
                    </div>
                    <TextareaAutosize
                        className="input"
                        placeholder="Message ChatAI"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                        onPaste={handlePaste}
                        spellCheck="false"
                        minRows={1}
                        rows={1}
                        ref={textareaRef}
                    />
                    <div className="sendIcon" style={sendButtonStyle} onClick={handleSendMessage}>
                        <ArrowTopIcon />
                    </div>
                    <div className="attachIcon" onClick={handleAttachClick}>
                        <AttachIcon />
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
            </div>
        </div>
    );
}