import React, { useState, useEffect } from 'react';
import './App.css';
import AuthScreen from './components/AuthScreen';
import ActiveScreen from './components/ActiveScreen';
import LoadingSpinner from './components/LoadingSpinner';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    const token = localStorage.getItem('token');
    let formData = new FormData();
    formData.append('token', token);

    try {
      const response = await fetch('https://chatai.uz/api/v2/auth/check', { method: 'POST', body: formData });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsAuthenticated(data.isAuthenticated);
      setLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  if (loading) { // Display nothing or loading screen until the auth check is complete
    return <LoadingSpinner/>; // Example: simple loading screen
  }

  if (!isAuthenticated) {
    return <AuthScreen setIsAuthenticated={setIsAuthenticated}/>;
  } else {
    return <ActiveScreen/>;
  }
}

export default App;