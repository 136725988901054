import React, { useState, useEffect } from "react";
import { EditIcon, ChatIcon, MenuIcon } from "./Icons";
import LoadingSpinner from './LoadingSpinner'; // Import the loading spinner

export default function SideBar({ chatId, setChatId, isSidebarOpen, setIsSidebarOpen }) {
    const chatsAmount = 1000;
    const [chats, setChats] = useState([]);
    const [chatsUpdater, setChatsUpdater] = useState(0);
    const [loading, setLoading] = useState(true); // Add loading state

    function fetchChats() {
        let body = new FormData();
        body.append('token', localStorage.getItem('token'));
        body.append('amount', chatsAmount);

        fetch('https://chatai.uz/api/v2/chats/get', { method: 'POST', body: body })
            .then(response => response.json())
            .then(response => {
                setChats(response.chats);
                setLoading(false); // Set loading to false after fetch is complete
            })
            .catch(error => {
                console.error('Error fetching chats:', error);
                setLoading(false); // Set loading to false in case of an error
            });
    }

    useEffect(() => {
        const intervalId = setInterval(fetchChats, 1000);
        return () => clearInterval(intervalId);
    }, [chatsUpdater]);

    function focusOnInput() {
        const input = document.getElementsByClassName('input')[0];
        input.focus();
        input.click();
    }

    const getDateString = (date) => date.toLocaleString('en-CA', { timeZone: 'Asia/Tashkent', year: 'numeric', month: '2-digit', day: '2-digit' }).split(',')[0];

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const todayStr = getDateString(today);
    const yesterdayStr = getDateString(yesterday);
    const sevenDaysAgoStr = getDateString(sevenDaysAgo);

    const todayChats = chats.filter(chat => chat.added === todayStr);
    const yesterdayChats = chats.filter(chat => chat.added === yesterdayStr);
    const previousSevenDaysChats = chats.filter(chat =>
        chat.added > sevenDaysAgoStr && chat.added < yesterdayStr
    );
    const previousChats = chats.filter(chat =>
        chat.added <= sevenDaysAgoStr
    );

    const renderChats = (chatList) => {
        return chatList.map(chat => (
            <div key={chat.id} className="chatBox">
                <div
                    onClick={() => { setChatId(chat.id); setIsSidebarOpen(false) }}
                    className={chat.id === chatId ? 'chatItem activeChat' : 'chatItem'}>
                    {chat.name}
                </div>
                <div
                    onClick={() => { setChatId(chat.id); setIsSidebarOpen(false) }}
                    className={chat.id === chatId ? 'chatShadow chatShadowHovered' : 'chatShadow'}>
                </div>
            </div>
        ));
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    // If loading, show LoadingSpinner, else show sidebar
    return (
        <div className={`sideBar ${isSidebarOpen ? 'active' : 'closed'}`}>
            <div className="sideBarMenuIcon" onClick={closeSidebar}><MenuIcon /></div>
            <div className="addChatBox">
                <div className="addChat chatItem" onClick={() => { setChatId(null); setIsSidebarOpen(false); focusOnInput() }}>
                    <ChatIcon />
                    <div className="addChatText">New Chat</div>
                </div>
            </div>
            {loading ? (
                <LoadingSpinner width="30px" height="30px"/>
            ) : (
                <>
                    {todayChats.length > 0 && (
                        <>
                            <div className="sideBarLine"></div>
                            <div className="chatsDate">Today</div>
                            {renderChats(todayChats)}
                        </>
                    )}
                    {yesterdayChats.length > 0 && (
                        <>
                            <div className="sideBarLine"></div>
                            <div className="chatsDate">Yesterday</div>
                            {renderChats(yesterdayChats)}
                        </>
                    )}
                    {previousSevenDaysChats.length > 0 && (
                        <>
                            <div className="sideBarLine"></div>
                            <div className="chatsDate">Previous 7 Days</div>
                            {renderChats(previousSevenDaysChats)}
                        </>
                    )}
                    {previousChats.length > 0 && (
                        <>
                            <div className="sideBarLine"></div>
                            <div className="chatsDate">Previous</div>
                            {renderChats(previousChats)}
                        </>
                    )}
                </>
            )}
        </div>
    )
}