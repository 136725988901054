import React, { useState } from "react";
import { FlashIcon, StarsIcon, ArrowIcon, CheckIcon, MenuIcon, EditIcon } from "./Icons";

export default function TopMenu({ model, setModel, imageSettings, setImageSettings, setChatId, isSidebarOpen, setIsSidebarOpen }) {
    const [showModels, setShowModels] = useState(false);
    const [isImageModelHovered, setIsImageModelHovered] = useState(false);
    const [isImageSettingsHovered, setIsImageSettingsHovered] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    function focusOnInput(){
        const input = document.getElementsByClassName('input')[0];
        input.focus();
        input.click();
    }

    return (
        <>
            <div className="topMenu">
                <div className="menuIcon" onClick={toggleSidebar}><MenuIcon/></div>
                <ModelName model={model} showModels={showModels} setShowModels={setShowModels} />
                <div className="modelBlockBox" style={{height: showModels ? 'auto': '0'}}>
                    <ModelsOptions model={model} setModel={setModel} showModels={showModels} setShowModels={setShowModels} isImageModelHovered={isImageModelHovered} setIsImageModelHovered={setIsImageModelHovered} isImageSettingsHovered={isImageSettingsHovered} setChatId={setChatId}/>
                    <ImageSettings isImageModelHovered={isImageModelHovered} setIsImageModelHovered={setIsImageModelHovered} showModels={showModels} setShowModels={setShowModels} isImageSettingsHovered={isImageSettingsHovered} setIsImageSettingsHovered={setIsImageSettingsHovered} imageSettings={imageSettings} setImageSettings={setImageSettings} setModel={setModel} setChatId={setChatId}/>
                </div>
                <div className="menuIcon newChatMenuIcon" onClick={() => {setChatId(null);focusOnInput()}}><EditIcon /></div>
            </div>
            <div className={"dimmedBackground" + (isSidebarOpen ? ' visible': '')} onClick={closeSidebar}></div>
        </>
    );
}

function ModelName({ model, showModels, setShowModels }) {
    const modelName = { 'text': 'GPT-4o', 'image': 'DALL·E 3' };

    return (
        <div className={showModels ? "modelBox showModels" : "modelBox"} onClick={() => setShowModels(!showModels)}>
            <div className="model">{modelName[model]}</div>
            <ArrowIcon />
        </div>
    );
}

function ModelsOptions({ model, setModel, showModels, setShowModels, isImageModelHovered, setIsImageModelHovered, isImageSettingsHovered, setChatId }) {
    const [style, setStyle] = useState({ cursor: 'default' });

    function focusOnInput(){
        const input = document.getElementsByClassName('input')[0];
        input.focus();
        input.click();
    }

    function onModelClick(modelName){
        if (showModels) { 
            setModel(modelName); 
            setShowModels(false); 
            localStorage.setItem('model', modelName);
            setChatId(null);
            focusOnInput();
        }
    }

    return (
        <div className={"modelsOptions" + (showModels ? ' visible' : '')}>
            <div className="modelsTitle">Model</div>
            <div className="modelOption" style={style} onClick={() => onModelClick('text')} onMouseOver={() => { if (showModels) { setStyle({ cursor: 'pointer' }) } else { setStyle({ cursor: 'default' }) } }}>
                <FlashIcon />
                <div className="modelName">GPT-4o</div>
                {model === 'text' ? <CheckIcon /> : null}
            </div>
            <div className="modelOption" style={style} onMouseLeave={() => setTimeout(() => setIsImageModelHovered(false), 100)} onMouseOver={() => { if (showModels) { setIsImageModelHovered(showModels); setStyle({ cursor: 'pointer' }) } else { setStyle({ cursor: 'default' }) } }} onClick={() => onModelClick('image')}>
                <StarsIcon />
                <div className="modelName">DALL·E 3</div>
                {model === 'image' ? <CheckIcon /> : null}
            </div>
        </div>
    );
}

function ImageSettings({ isImageModelHovered, setIsImageModelHovered, showModels, setShowModels, isImageSettingsHovered, setIsImageSettingsHovered, imageSettings, setImageSettings, setModel, setChatId}) {
    const [style, setStyle] = useState({ cursor: 'default' });

    function focusOnInput(){
        const input = document.getElementsByClassName('input')[0];
        input.focus();
        input.click();
    }

    function setImageModel() {
        setIsImageModelHovered(false);
        setModel('image');
        setShowModels(false);
        localStorage.setItem('model', 'image');
        setIsImageSettingsHovered(false);
        focusOnInput();
    }

    function setImageQuality(quality) {
        const imageSettingCopy = { ...imageSettings };
        imageSettingCopy.quality = quality;
        setImageSettings(imageSettingCopy);
        localStorage.setItem('imageSettings', JSON.stringify(imageSettingCopy));
        setImageModel();
        setChatId(null);
        focusOnInput();
    }

    function setImageShape(shape) {
        const imageSettingCopy = { ...imageSettings };
        imageSettingCopy.shape = shape;
        setImageSettings(imageSettingCopy);
        localStorage.setItem('imageSettings', JSON.stringify(imageSettingCopy));
        setImageModel();
        setChatId(null);
        focusOnInput();
    }

    return (
        <div onMouseOver={() => { setIsImageSettingsHovered(showModels) }} onMouseLeave={() => { setIsImageSettingsHovered(false) }} className={"imageSettings" + ((isImageModelHovered || isImageSettingsHovered) ? " imageSettingsVisible" : "")}>
            <div className="qualityRow">
                <div onClick={() => { if (showModels) { setImageShape('square') } }} onMouseOver={() => { if (showModels) { setStyle({ cursor: 'pointer' }) } else { setStyle({ cursor: 'default' }) } }} style={style} className={"imageSetting" + ((imageSettings.shape === 'square') ? ' imageSettingChosen' : '')}>Square</div>
                <div onClick={() => { if (showModels) { setImageShape('landscape') } }} onMouseOver={() => { if (showModels) { setStyle({ cursor: 'pointer' }) } else { setStyle({ cursor: 'default' }) } }} style={style} className={"imageSetting" + ((imageSettings.shape === 'landscape') ? ' imageSettingChosen' : '')}>Landscape</div>
                <div onClick={() => { if (showModels) { setImageShape('portrait') } }} onMouseOver={() => { if (showModels) { setStyle({ cursor: 'pointer' }) } else { setStyle({ cursor: 'default' }) } }} style={style} className={"imageSetting" + ((imageSettings.shape === 'portrait') ? ' imageSettingChosen' : '')}>Portrait</div>
            </div>
            <div className="qualityRow">
                <div onClick={() => { if (showModels) { setImageQuality('hd') } }} onMouseOver={() => { if (showModels) { setStyle({ cursor: 'pointer' }) } else { setStyle({ cursor: 'default' }) } }} style={style} className={"imageSetting" + ((imageSettings.quality === 'hd') ? ' imageSettingChosen' : '')}>HD</div>
                <div onClick={() => { if (showModels) { setImageQuality('standard') } }} onMouseOver={() => { if (showModels) { setStyle({ cursor: 'pointer' }) } else { setStyle({ cursor: 'default' }) } }} style={style} className={"imageSetting" + ((imageSettings.quality === 'standard') ? ' imageSettingChosen' : '')}>Standard</div>
            </div>
        </div>
    );
}