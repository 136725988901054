import React, { useState, useRef, useEffect, useMemo } from "react";
import ReactMarkdown from 'react-markdown';
import { DownloadIcon, LogoIcon, CopyIcon, CloseIcon } from "./Icons";
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function MessagesWindow({ chatId }) {
  const messages = useRef([]);
  const [messagesUpdater, setMessagesUpdater] = useState(0);
  const messagesEndRef = useRef(null);
  const messageKeys = useRef([]);
  const scrollBottom = useRef(0);
  const isScrollingUp = useRef(false);
  const lastScrollTop = useRef(0);

  function arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  }

  const fetchMessages = () => {
    setMessagesUpdater(messagesUpdater + 1);

    let body = new FormData();
    body.append('token', localStorage.getItem('token'));
    body.append('chat_id', chatId);

    return fetch('https://chatai.uz/api/v2/messages/get', { method: 'POST', body: body })
      .then(response => response.json())
      .then(response => {
        if (!arraysAreEqual(Object.keys(response.messages), messageKeys.current)) {
          messageKeys.current = Object.keys(response.messages);
          let lastMessageKey = messageKeys.current.slice(-1)[0];

          messages.current = Object.entries(response.messages).map(([key, value]) => <Message key={key} id={key} value={value} isLast={key == lastMessageKey} openModal={openModal} />);
          setMessagesUpdater(messagesUpdater + 1);
          scrollToBottom();
          scrollBottom.current++;
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function isFocusedOnInput() {
    const input = document.getElementsByClassName('input')[0];

    return document.activeElement === input;
  }

  const isNearBottom = () => {
    const threshold = 300;
    const position = messagesEndRef.current.getBoundingClientRect().bottom;
    const nearBottom = position < window.innerHeight + threshold;
    return nearBottom;
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    const intervalId = setInterval(fetchMessages, 250);
    return () => clearInterval(intervalId);
  }, [messagesUpdater]);

  if (scrollBottom.current) {
    scrollBottom.current++;

    if (scrollBottom.current > 5 && chatId != null) {
      scrollBottom.current = 0;
      scrollToBottom();
    }
  }

  useEffect(() => {
    fetchMessages();
    scrollBottom.current = 0;
    isScrollingUp.current = false;
  }, [chatId])

  useEffect(() => {
    if (isNearBottom()) {
      if (!isScrollingUp.current && !isFocusedOnInput()) {
        scrollToBottom();
      }
    }
  }, [messagesUpdater]);

  const [modalImage, setModalImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (image) => {
    setModalImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setTimeout(() => setModalImage(null), 300);
  };

  const downloadImage = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.target = '_blank';
    link.setAttribute('download', 'downloaded_image');

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27 && modalImage) {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [modalImage]);

  function setScrollDirection() {
    let currentScroll = document.getElementsByClassName('messagesBox')[0].scrollTop;

    if (currentScroll > lastScrollTop.current) {
      isScrollingUp.current = false;
    } else {
      isScrollingUp.current = true;
    }

    lastScrollTop.current = currentScroll;
  }

  return (
    <div className="messagesWindow">
      <div className="messagesBox" onScroll={setScrollDirection}>
        <div className="messagesContent">
          {messages.current}
          <div ref={messagesEndRef} className="scrollBox"></div>
        </div>
        {modalImage && (
          <div className="modal" onClick={closeModal}>
            <span className="close" onClick={closeModal}><CloseIcon/></span>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <img src={modalImage.url} />
              <div className="download" onClick={() => downloadImage(modalImage.url)}>
                <DownloadIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Message({ id, value, isLast, openModal }) {
  const message = useRef(value);
  const [messageUpdater, setMessagesUpdater] = useState(0);

  const display = useRef(value);
  const [displayUpdater, setDisplayUpdater] = useState(0);

  function fetchMessage() {
    const body = new FormData();
    body.append('token', localStorage.getItem('token'));
    body.append('message_id', id);

    fetch('https://chatai.uz/api/v2/message/get', { method: 'POST', body: body })
      .then(response => response.json())
      .then(response => {
        message.current = response.message;
        setMessagesUpdater(messageUpdater + 1);
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (!isLast) { return }
    const intervalId = setInterval(fetchMessage, 200);
    return () => clearInterval(intervalId);
  }, [messageUpdater]);

  function updateMessage() {
    if (message.current.content.length > display.current.content.length) {
      display.current.content = message.current.content.substring(0, display.current.content.length + 1);
    }

    display.current.images = message.current.images;
    setDisplayUpdater(displayUpdater + 1);
  }

  useEffect(() => {
    if (!isLast) { return }
    const intervalId = setInterval(updateMessage, 2);
    return () => clearInterval(intervalId);
  }, [displayUpdater]);

  // Custom component to capture code blocks and apply highlight.js
  const components = {
    code({ node, inline, className, children, ...props }) {
        const language = /language-(\w+)/.exec(className || '')?.[1];
        return !inline && language ? (
        <CodeBlock language={language} value={String(children).trim()} />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    pre({ node, ...props }) {
      return <>{props.children}</>;
    }
  };

  const memoizedMarkdown = useMemo(() => {
    if(display.current.sender === 'system') {
      return <ReactMarkdown components={components}>{display.current.content}</ReactMarkdown>;
    } else {
      return display.current.content;
    }
  }, [display.current.content, display.current.sender]);

  return (
    <div className={"message " + display.current.sender} key={id}>
      {display.current.sender === 'system' && <div className="logoIcon"><LogoIcon /></div>}
      <div className="messageBox">
        <div className={"messageImages " + display.current.sender}>
          {display.current.images.map(image =>
            <div className="messageImage" key={image.id} onClick={() => openModal(image)}>
              <img src={image.url} loading="lazy" />
            </div>
          )}
        </div>
        <div className={"messageContent " + display.current.sender}>
          <div className={"messageContentCover " + ((display.current.images.length > 0) ? 'withImages' : '')}>
            {memoizedMarkdown}
          </div>
        </div>
      </div>
    </div>
  );
}

const CodeBlock = ({ language, value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the "copied" status after 2 seconds
  };

  // Use hljs to highlight the code
  let highlightedCode

  try {
    highlightedCode = hljs.highlight(value, { language }).value;
  } catch {
    highlightedCode = hljs.highlight(value, { language: 'c' }).value;
  }

  // Split the highlighted code into lines for safe rendering
  const highlightedLines = highlightedCode.split('\n').map((line, index) => (
    <><span key={index} dangerouslySetInnerHTML={{ __html: line }} /><br/></>
  ));

  return (
    <div className="code-block">
      <div className="code-block-header">
        <span className="language-label">{language}</span>
        <CopyToClipboard text={value} onCopy={handleCopy}>
          <button className="copy-code-btn">
            {copied ? ('Copied!') : (
              <div className="copyCode">
                <CopyIcon/> <span>Copy code</span>
              </div>
            )}
          </button>
        </CopyToClipboard>
      </div>
      <pre>
        <code>
          {highlightedLines}
        </code>
      </pre>
    </div>
  );
};