import React, { useState, useRef } from "react";

export default function AuthScreen({setIsAuthenticated}){
    let passwordRef = useRef(null);
    let token = useRef(null)

    function generateToken(){
        let timestamp = new Date().getTime() * 1000;
        let randNum = Math.floor(Math.random() * 10**15);
        token = timestamp + '' + randNum
    }

    function authenticate(){
        generateToken()
        localStorage.setItem('token', token)
        let password = passwordRef.current.value;

        let formData = new FormData();
        formData.append('password', password);
        formData.append('token', token);

        fetch('https://chatai.uz/api/v2/auth', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(response => {
            setIsAuthenticated(response.status);
        })
        .catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="auth">
            <p className="authText">Authenticate</p>
            <input ref={passwordRef} type="password" className="password" placeholder="Password"/>
            <button className="continue" onClick={authenticate}>Continue</button>
        </div>
    )
}