import React, { useState } from "react";
import SideBar from "./SideBar";
import MainWindow from "./MainWindow";

export default function ActiveScreen(){
    let [chatId, setChatId] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <div className="activeScreen">
            <SideBar chatId={chatId} setChatId={setChatId} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
            <MainWindow chatId={chatId} setChatId={setChatId} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
        </div>
    )
}