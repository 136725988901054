import React, { useState, useRef } from "react";
import TopMenu from "./TopMenu";
import MessagesWindow from "./MessagesWindow";
import Input from "./Input";
import LoadingSpinner from "./LoadingSpinner"

export default function MainWindow({chatId, setChatId, isSidebarOpen, setIsSidebarOpen}){
    let savedModel = localStorage.getItem('model');
    let savedImageSettings = JSON.parse(localStorage.getItem('imageSettings'));

    if (!savedModel){
        savedModel = 'text';
    }

    if (!savedImageSettings){
        savedImageSettings = {shape: 'square', quality: 'standard'}
    }

    let [model, setModel] = useState(savedModel);
    let [imageSettings, setImageSettings] = useState(savedImageSettings);

    return (
        <div className="mainWindow">
            <TopMenu model={model} setModel={setModel} imageSettings={imageSettings}setImageSettings={setImageSettings} setChatId={setChatId} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
            <MessagesWindow chatId={chatId} model={model} imageSettings={imageSettings}/>
            <Input model={model} chatId={chatId} setChatId={setChatId} imageSettings={imageSettings}/>
        </div>
    )
}