import React from 'react';
import '../App.css'; // Ensure styles are imported if defined in App.css

function LoadingSpinner({width = '50px', height = '50px'}){
    return (
        <div className="spinner-container">
            <div style={{width: width, height: height}} className="spinner"></div>
        </div>
    );
};

export default LoadingSpinner;